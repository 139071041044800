import React from 'react';
import { Helmet } from 'react-helmet';

const ChiSiamo = () => {
  return (
    <>
      <Helmet>
        <title>Chi Siamo - ULTIME OFFERTE</title>
        <meta name="description" content="Scopri chi siamo e la nostra missione di fornire le migliori informazioni sui prodotti tecnologici in Italia." />
      </Helmet>
      <div className="max-w-5xl mx-auto p-4">
        <h2 className="text-3xl font-bold text-gray-600 my-4">Chi Siamo</h2>
        <div className="w-full h-96 bg-gray-300 mb-6 flex items-center justify-center">
        <img src="/img/chi-siamo.png" alt="Immagine del team" className="w-full h-full object-cover" />
        </div>
        <p className="mb-4">
          Benvenuti nella nostra pagina "Chi Siamo". Siamo un team appassionato di esperti tecnologici dedicati a fornire le migliori informazioni e confronti sui prodotti tecnologici disponibili in Italia.
        </p>
        <p className="mb-4">
          La nostra missione è aiutare i consumatori a prendere decisioni informate quando si tratta di acquistare nuovi dispositivi e gadget tecnologici. Analizziamo attentamente le specifiche tecniche, le prestazioni e il rapporto qualità-prezzo di ogni prodotto per offrirvi una panoramica completa e imparziale.
        </p>
        <p className="mb-4">
          Il nostro team è composto da:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>Esperti di tecnologia con anni di esperienza nel settore</li>
          <li>Analisti di mercato che seguono costantemente le tendenze e le novità</li>
          <li>Redattori specializzati in recensioni di prodotti tecnologici</li>
          <li>Appassionati di tecnologia che testano personalmente i dispositivi</li>
        </ul>
        <p className="mb-4">
          Ci impegniamo a mantenere le nostre recensioni e confronti sempre aggiornati, assicurandoci di fornirvi le informazioni più recenti e pertinenti su una vasta gamma di prodotti tecnologici, tra cui smartphone, tablet, computer, dispositivi smart home e molto altro. La vostra soddisfazione e la vostra fiducia sono la nostra priorità.
        </p>
        <p>
          Grazie per aver scelto il nostro sito come vostra risorsa per la scelta dei vostri prossimi acquisti tecnologici. Se avete domande o feedback, non esitate a contattarci.
        </p>
      </div>
    </>
  );
};

export default ChiSiamo;
