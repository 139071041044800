import React from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink } from 'react-router-dom';
import LaptopComparison from './components/productComparison';
import ChiSiamo from './components/ChiSiamo';
import TermsAndConditions from './components/TermsAndConditions';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      <div className="App">
        <header className="bg-gray-200 p-4">
          <nav className="flex flex-col sm:flex-row justify-between items-center max-w-5xl mx-auto">
            <div className="flex items-center mb-2 sm:mb-0">
              <img src="/img/logo.png" alt="Logo" className="h-8 w-auto mr-2" />
              <img src="/img/it.webp" alt="Italy Flag" className="h-6 w-auto ml-2" />
            </div>
            <div className="flex space-x-2">
              <NavLink 
                to="/" 
                className={({ isActive }) => 
                  isActive ? "bg-orange-500 text-gray-800 font-bold px-2 py-1 md:px-4 md:py-2 rounded text-xs md:text-sm" : 
                  "bg-orange-500 text-white px-2 py-1 md:px-4 md:py-2 rounded text-xs md:text-sm"
                }
              >
                Home
              </NavLink>
              <NavLink 
                to="/chi-siamo" 
                className={({ isActive }) => 
                  isActive ? "bg-orange-500 text-gray-800 font-bold px-2 py-1 md:px-4 md:py-2 rounded text-xs md:text-sm" : 
                  "bg-orange-500 text-white px-2 py-1 md:px-4 md:py-2 rounded text-xs md:text-sm"
                }
              >
                Chi siamo
              </NavLink>
            </div>
          </nav>
        </header>

        <Routes>
          <Route path="/" element={<LaptopComparison />} />
          <Route path="/chi-siamo" element={<ChiSiamo />} />
          <Route path="/termini-e-condizioni" element={<TermsAndConditions />} />
        </Routes>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
