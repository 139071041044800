import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';

const LaptopItem = ({ id, title, brand, rating, price, link, isTopChoice, isDeal, details, discount, linkOffer, linkImage }) => {
  const [expanded, setExpanded] = useState(false);
  const starRating = Math.round((rating / 10) * 5);

  const getRatingText = (rating) => {
    if (rating >= 9.5) return "Eccezionale";
    if (rating >= 8.5) return "Ottimo";
    if (rating >= 7.5) return "Molto Buono";
    if (rating >= 6.5) return "Buono";
    return "Discreto";
  };

  const getRatingColor = (rating) => {
    if (rating >= 9.5) return "text-green-600";
    if (rating >= 8.5) return "text-green-500";
    if (rating >= 7.5) return "text-yellow-500";
    if (rating >= 6.5) return "text-yellow-600";
    return "text-orange-500";
  };

  return (
    <div className="bg-white p-2 md:p-4 rounded shadow mb-4 transition-all duration-300 ease-in-out hover:shadow-lg">
      <div className="flex flex-wrap items-center mb-2 md:mb-4">
        {isTopChoice && (
          <span className="bg-red-500 text-white text-xs md:text-sm px-2 py-1 rounded mr-2 mb-1 md:mb-0">Scelta Migliore</span>
        )}
        {isDeal && (
          <span className="bg-green-500 text-white text-xs md:text-sm px-2 py-1 rounded mb-1 md:mb-0">Affare</span>
        )}
      </div>
      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-1/12 text-xl md:text-2xl font-bold text-gray-500 mb-2 md:mb-0">{id < 10 ? `0${id}` : id}</div>
        <div className="w-full md:w-3/12 mb-2 md:mb-0">
          <img src={linkImage} alt={title} className="w-full max-w-[200px] mx-auto md:mx-0"/>
        </div>
        <div className="w-full md:w-4/12 px-0 md:px-4 flex flex-col justify-between mb-2 md:mb-0">
          <div>
            <h3 className="text-base md:text-lg font-bold text-gray-600">{title}</h3>
            <p className="text-sm md:text-base text-gray-600">{brand}</p>
            {discount && (
              <div className="flex justify-center md:justify-start">
                <span className="inline-block bg-orange-500 text-white font-bold px-2 py-1 rounded text-xs md:text-sm mb-2">
                  {discount}% Sconto
                </span>
              </div>
            )}
          </div>
          <button 
            className="text-orange-500 self-center md:self-start mt-2 transition-colors duration-200 hover:text-orange-700 text-sm md:text-base"
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? (
              <>
                Mostra Di Meno <i className="fas fa-chevron-up ml-1 transition-transform duration-200 ease-in-out transform rotate-180"></i>
              </>
            ) : (
              <>
                Mostra Di Più <i className="fas fa-chevron-down ml-1 transition-transform duration-200 ease-in-out"></i>
              </>
            )}
          </button>
        </div>
        <div className="w-full md:w-2/12 text-center border-t md:border-t-0 md:border-l-2 md:border-r-2 border-gray-200 flex flex-col justify-center py-2 md:py-0">
          <div className="text-3xl md:text-4xl font-bold text-gray-500">{rating}</div>
          <div className={`${getRatingColor(rating)} font-bold text-base md:text-lg`}>{getRatingText(rating)}</div>
          <div className="text-yellow-400">
            {[...Array(5)].map((_, i) => (
              <i key={i} className={`fas fa-star ${i < starRating ? 'text-yellow-400' : 'text-gray-300'}`}></i>
            ))}
          </div>
        </div>
        <div className="w-full md:w-2/12 text-center flex flex-col justify-center items-center mt-2 md:mt-0">
          <div className="text-sm font-bold text-gray-500 mb-2">{price}</div>
          <button 
            className="bg-orange-500 text-white px-4 py-2 rounded transition-colors duration-200 hover:bg-orange-600 text-sm md:text-base"
            onClick={() => link && window.open(link, '_blank')}
          >
            Visualizza offerta
          </button>
          <img src="https://i2.hd-cdn.it/new_files/img/loghi_negozi_prezzi/logo_amazon.png" alt="amazon" className="w-16 md:w-20 px-4 border-t border-gray-200 pt-2 mt-2"/>
        </div>
      </div>
      <CSSTransition
        in={expanded}
        timeout={300}
        classNames="details"
        unmountOnExit
      >
        <div className="mt-4 w-full">
          <h4 className="font-bold mb-2 text-sm md:text-base">PRINCIPALI CARATTERISTICHE:</h4>
          <ul className="list-disc list-inside grid grid-cols-1 md:grid-cols-2 gap-2">
            {details.map((detail, index) => (
              <li key={index} className="text-xs md:text-sm text-gray-700">{detail}</li>
            ))}
          </ul>
        </div>
      </CSSTransition>
    </div>
  );
};

export default LaptopItem;
