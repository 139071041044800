export const fetchLaptops = async () => {
  // Simulate API delay
  await new Promise(resolve => setTimeout(resolve, 1000));
  
  // Mock data
  // Mock data
  return [
    {
        "id": 1,
        "title": "Hisense TV 55\" 4K Ultra HD 55A6N, Smart TV VIDAA U7, Dolby Vision, HDR 10+, DTS Virtual:X, Game Mode, Alexa Built-in, VIDAA Voice, Tuner DVB-T2/S2 HEVC 10, lativù 4K",
        "brand": "HISENSE",
        "rating": 9.6,
        "price": "349,00 €",
        "inches": 55,
        "link": "https://amzn.to/3Aeees8",
        "isTopChoice": true,
        "isDeal": true,
        "discount": 22,
        "linkOffer": null,
        "linkImage": "https://m.media-amazon.com/images/I/71wnLkMdYnL._AC_SL1500_.jpg",
        "details": [
          "Smart TV 4K 55\": Qualità 4K eccezzionalmente dettagliata per tutti i tuoi contenuti preferiti",
          "Dolby Vision / HDR 10+ / HLG : Un'esperienza di intrattenimento premium con Dolby",
          "Smart TV VIDAA U7.6 con Alexa Integrato: Sistema operativo all'avanguardia che unifica i tuoi contenuti internazionali e locali",
          "Audio DTS Virtual X con Bluetooth: Esperienza audio immersiva e coinvolgente",
          "AirPlay2 + Android Screen Sharing: Opzioni avanzate di condivisione dello schermo"
        ]
      },
    {
        "id": 2,
        "title": "CL 55V6B TV 55”, 4K HDR, Ultra HD, Google TV con design senza bordi, Dolby Audio, compatibile con Google Assistant",
        "brand": "TCL",
        "rating": 8.3,
        "price": "349,00 €",
        "inches": 55,
        "link": "https://amzn.to/3YyNI5d",
        "isTopChoice": false,
        "isDeal": true,
        "discount": 30,
        "linkOffer": null,
        "linkImage": "https://m.media-amazon.com/images/I/711+poZJYOL._AC_SL1500_.jpg",
        "details": [
          "Dolby Audio: audio immersivo e avvolgente per un'esperienza di audio surround a 360 gradi",
          "HDR MULTIFORMAT: supporta HDR10, HDR HLG, HDR10+, HDR DOLBY VISION",
          "GAME MASTER 2.0: con HDMI 2.1 e ALLM, sperimenterai automaticamente la latenza più bassa e le migliori impostazioni dell'immagine per i tuoi videogame",
          "Google TV: raccoglie film, serie, programmi TV e molto altro dalle tue app e abbonamenti, e li organizza apposta per te"
        ]
      },
      {
        "id": 3,
        "title": "LG OLED 55', Smart TV 4K, OLED55B36LA, Serie B3 2023, Processore α7 Gen6, AI Super Upscaling, Dolby Vision, Dolby Atmos, 2 HDMI 2.1 @@48Gbps, VRR, ThinQ AI, Wi-Fi, webOS 23",
        "brand": "LG",
        "rating": 9.2,
        "price": "949,00 €",
        "inches": 55,
        "link": "https://amzn.to/3Yh8TZh",
        "isTopChoice": false,
        "isDeal": false,
        "discount": null,
        "linkOffer": null,
        "linkImage": "https://m.media-amazon.com/images/I/71OCrt1uqML.__AC_SX300_SY300_QL70_ML2_.jpg",
        "details": [
          "TV 55 POLLICI LG OLED CON PIXEL AUTO-ILLUMINANTI: la miglior tecnologia per goderti film, sport e videogiochi, grazie ai pixel autoilluminanti che ti danno colori perfetti, nero perfetto e contrasto infinito",
          "PROCESSORE α7 GEN6 CON AI: un processore di ultima generazione con Intelligenza Artificiale che ottimizza automaticamente immagini e audio in base a cosa stai guardando",
          "DESIGN MINIMALE CON BORDI SOTTILI: arreda il tuo ambiente con un TV capace di catturare il tuo sguardo in tutte le occasioni, anche quando è spento",
          "DOLBY VISION IQ E DOLBY ATMOS: porta il cinema a casa tua con le tecnologie Dolby, per immagini profonde e ricche di sfumature e un audio posizionale più coinvolgente",
          "IL TV NEXT-GEN PER IL GAMING NEXT-GEN: il sogno di tutti i gamer, questo TV ti dà un gameplay fluido e reattivo, input lag ridotto e 2 porte HDMI 2.1 per gameplay in 4K @120fps con VRR e Dolby Vision",
          "ASSISTENTE VOCALE THINQ AI: il TV risponde ai tuoi comandi vocali per cercare nuovi contenuti sulle piattaforme di streaming, controllare i tuoi dispositivi connessi e trovare risposte su Internet",
          "SMART TV WEBOS 23 CON TELECOMANDO PUNTATORE: tantissime app di streaming, nuove schede contenuti e un telecomando che rende tutto più semplice e intuitivo"
        ]
      },
      {
        "id": 4,
        "title": "Samsung TV QE32Q50AEUXZT QLED Full HD, Smart TV 32\" Quantum HDR, 100% Volume Colore, OTS Lite, Slim Design, Integrato con Bixby e Alexa compatibile con Google Assistant, Black 2023",
        "brand": "SAMSUNG",
        "rating": 8.8,
        "price": "342,00 €",
        "inches": 32,
        "link": "https://amzn.to/3YuoLc7",
        "isTopChoice": false,
        "isDeal": false,
        "discount": null,
        "linkOffer": null,
        "linkImage": "https://m.media-amazon.com/images/I/81Xs8NvTjWL._AC_SL1500_.jpg",
        "details": [
          "Quantum HDR: vivi ogni scena come dal vivo con colori brillanti e dettagli definiti",
          "Motion Xcelerator: esperienza di gioco elevata con performance bilanciate per un gameplay fluido",
          "100% del volume colore con tecnologia Quantum Dot per immagini brillanti",
          "AirSlim: design sottile ed elegante per una posizionamento versatile",
          "Predisposto per il nuovo Digitale Terrestre 2.0"
        ]
      },
      {
        "id": 5,
        "title": "CHiQ L40QG7V TV QLED da 40 pollici, FHD, HDR10, design con cornice ultrasottile in metallo, Google TV, Chromecast integrato, Assistente di Google, CPU quad-core, Wi-Fi a doppia banda da 2,4/5 Ghz",
        "brand": "CHiQ",
        "rating": 8.4,
        "price": "249,90 €",
        "inches": 40,
        "link": "https://amzn.to/3UfSsv9",
        "isTopChoice": false,
        "isDeal": false,
        "discount": null,
        "linkOffer": null,
        "linkImage": "https://m.media-amazon.com/images/I/81VJUjipa0L._AC_SL1500_.jpg",
        "details": [
          "QLED&HDR10: Colori più ricchi e una gamma di colori più ampia rispetto ai televisori LED standard. Chiarezza e contrasto realistici grazie alla tecnologia HDR10.",
          "Smart Google TV: Accesso a oltre 5.000 app tramite Google Play Store. Supporta Chromecast e la condivisione multipla.",
          "Hardware potente: CPU quad-core A55, 1,5 GB di RAM/8 GB di ROM, WiFi 2,4 GHz/5 GHz, molteplici ingressi e uscite.",
          "DBX-TV con 4 modalità, Dolby Audio e altoparlante 2x8 W per un suono perfetto.",
          "Dimensioni del prodotto con supporto: 898*180*560 mm; senza staffa: 898*87*515 mm. Montaggio VESA tipo MIS-F, distanza fori 20*20 cm."
        ]
      },
      {
        "id": 6,
        "title": "LG Smart TV 32LQ63 - Televisore 80 cm (32\") Full HD Smart TV-wifi Nero",
        "brand": "LG",
        "rating": 9,
        "price": "195,00 €",
        "inches": 32,
        "link": "https://amzn.to/3YtBwDS",
        "isTopChoice": false,
        "isDeal": true,
        "discount": 22,
        "linkOffer": null,
        "linkImage": "https://m.media-amazon.com/images/I/51rYs0wYyRL._AC_SL1200_.jpg",
        "details": [
          "Display FHD 2K con LED diretto; dimensioni schermo di 80 cm (32 pollici); dimensioni esterne (senza supporto): 73,6 x 43,7 x 8,29 cm, norma VESA: 200 x 200 mm",
          "Smart TV: webOS22 (LG ThinQ AI) con Google Assistant & Amazon Alexa, compatibile con Amazon Echo, Google Home, Apple AirPlay 2 & HomeKit; Home Dashboard",
          "Processore: Processore Gel-5 Gen5 AI con AI Brightness Control & AI Sound (Virtual 5.1 Up-mix); caratteristiche gaming: Compatibile con HGiG, Game Optimizer (GameGenre)",
          "Immagine e suono: Active HDR con HDR10 Pro (solo 2K) & HLG (solo 2K), HDR Dynamic Tone Mapping, Resolution Upscaler, riduzione del rumore a due livelli; sistema audio 2.0 da 10 Watt (5 W per canale)",
          "Tipo di sintonizzatore e connettività: Single Triple Tuner (DVB-T2/-C/-S2) con registrazione USB, LAN (Ethernet), WLAN (802.11ac), Bluetooth 5.0, 2 HDMI 1.4 (con eARC e LG SIMPLINK), uscita digitale ottica (S/PDIF, fibra ottica), slot CI+"
        ]
      },
      {
        "id": 7,
        "title": "JCL JCL32RWHD Smart TV 32\" HD Ready 720p Sistema Operativo webOS TV 8.1, WiFi Ethernet, Netflix Youtube, HDMI x3, VESA, DVB +T/T2/C/S/S2",
        "brand": "JCL",
        "rating": 7.8,
        "price": "130,05 €",
        "inches": 32,
        "link": "https://amzn.to/48dDgnS",
        "isTopChoice": false,
        "isDeal": false,
        "discount": null,
        "linkOffer": null,
        "linkImage": "https://m.media-amazon.com/images/I/61OHPj+6AaL._AC_SL1500_.jpg",
        "details": [
          "Sistema Operativo webOS tv 8.1, accesso a centinaia di programmi e applicazioni scaricabili gratuitamente",
          "Dimensioni schermo: 32\" - Risoluzione HD Ready 720p, rapporto 16:9",
          "Sintonizzatore DVB +T/T2/C/S/S2",
          "Connettività: HDMI x3, RCA, Uscita Audio Cuffie, RJ45, WiFi",
          "Supporta servizi Internet come Netflix, YouTube, Browser"
        ]
      },
      {
        "id": 8,
        "title": "Hisense TV 43\" 4K Ultra HD 43E63NT, Smart TV VIDAA U7, Dolby Vision, HDR 10+, DTS Virtual:X, Game Mode Plus, Alexa Built-in, VIDAA Voice, Tuner DVB-T2/S2 HEVC 10, lativù 4K",
        "brand": "HISENSE",
        "rating": 8.6,
        "price": "279,00 €",
        "inches": 43,
        "link": "https://amzn.to/4hc5ROq",
        "isTopChoice": false,
        "isDeal": false,
        "discount": null,
        "linkOffer": null,
        "linkImage": "https://m.media-amazon.com/images/I/815A+PpslhL._AC_SL1500_.jpg",
        "details": [
          "Smart TV 4K 43\", Risoluzione 3840x2160",
          "Dolby Vision/HDR 10+/HLG: offre un'esperienza visiva eccezionale con colori brillanti e dettagli accurati.",
          "Smart TV VIDAA U7 con Alexa Integrato: accesso semplificato a un'ampia gamma di contenuti e controllo vocale integrato.",
          "AirPlay2 + Android Screen Sharing: permette la condivisione facile dello schermo da dispositivi Apple e Android.",
          "Audio DTS Virtual X con Bluetooth: audio surround virtuale per un'esperienza sonora coinvolgente."
        ]
      },
      {
        "id": 9,
        "title": "Samsung TV QE50Q60DAUXZT QLED 4K, Smart TV 50\" Quantum processor Lite 4K, AirSlim, DVBT-2, Q-Symphony & OTS Lite, Integrato con Alexa e Bixby, compatibile con Google Assistant, Titan Gray 2024",
        "brand": "SAMSUNG",
        "rating": 8.8,
        "price": "499,00 €",
        "inches": 50,
        "link": "https://amzn.to/4h9RD0F",
        "isTopChoice": false,
        "isDeal": true,
        "discount": 10,
        "linkOffer": null,
        "linkImage": "https://m.media-amazon.com/images/I/71wE9cYoX7L._AC_SL1500_.jpg",
        "details": [
          "Smart TV 50 pollici, Quantum processor Lite con risoluzione 4K per colori e luminosità straordinari, AirSlim Design con un profilo incredibilmente sottile",
          "Tecnologia Quantum Dot che cattura la luce e la trasforma in colori mozzafiato conservando tutto il loro realismo a ogni livello di luminosità",
          "Samsung TV Plus con una varietà di canali che offrono notizie, sport, intrattenimento e altro ancora",
          "OTS Lite e Q-Symphony per un'esperienza sonora completamente immersiva, Adaptive Sound Pro per un suono autentico",
          "Gaming Hub, Auto Low Latency Mode e Game Bar per una perfetta esperienza di gioco",
          "Include telecomando, cavo di alimentazione e manuale di istruzioni, Dimensioni con base: 111.8 x 68.2 x 22.4 cm, Colore: Titan Gray"
        ]
      },
      {
        "id": 10,
        "title": "LG QNED 65'', Serie 85 2024, 65QNED85T6C, Smart TV 4K, Processore α8, Design slim, 20W, AI Picture Pro, 4 HDMI 2.1 120Hz, VRR, FreeSync Premium, Alexa, Wi-Fi, webOS 24, Telecomando puntatore",
        "brand": "LG",
        "rating": 9,
        "price": "899,00 €",
        "inches": 65,
        "link": "https://amzn.to/3BONDlT",
        "isTopChoice": false,
        "isDeal": true,
        "discount": 40,
        "linkOffer": null,
        "linkImage": "https://m.media-amazon.com/images/I/81wO+9hs1jL._AC_SL1500_.jpg",
        "details": [
          "TECNOLOGIA QUANTUM DOT E NANOCELL: goditi colori ancora più raffinati e puri rispetto a un TV LED tradizionale grazie a questa tecnologia progettata da LG",
          "PROCESSORE α8 CON AI: prestazioni grafiche, di AI e di elaborazione ottimizzate per immagini e suoni in base a ciò che guardi",
          "ADVANCED LOCAL DIMMING: migliora il contrasto dell'immagine ed esalta i dettagli grazie alla gestione della retroilluminazione",
          "DESIGN SLIM: stile essenziale e rifinito, spessore dello schermo ridotto",
          "webOS Re:New Program: 4 aggiornamenti del sistema operativo nei prossimi 5 anni per un'esperienza sempre aggiornata",
          "GAMING: gameplay fluido e reattivo, 4 porte HDMI per gameplay in 4K @120fps con VRR e Freesync",
          "SMART TV WEBOS 24 CON THINQ AI: numerose app di streaming, schede contenuti e comandi vocali tramite l'Intelligenza Artificiale ThinQ AI",
          "TELECOMANDO PUNTATORE: controllo intuitivo della Smart TV con movimenti del polso"
        ]
      },
      {
        "id": 11,
        "title": "Hisense TV 85 pollici QLED 4K 85E77NQ, Smart TV VIDAA U7, Dolby Vision, HDR 10+, Game Mode Plus, Dolby Atmos, Alexa Built-in, VIDAA Voice, Tuner DVB-T2/S2 HEVC 10, lativù 4K",
        "brand": "HISENSE",
        "rating": 8.6,
        "price": "949,00€",
        "inches": 85,
        "link": "https://amzn.to/48FDW5K",
        "isTopChoice": true,
        "isDeal": true,
        "discount": 33,
        "linkOffer": null,
        "linkImage": "https://m.media-amazon.com/images/I/81K7ob+J6mL._AC_SL1500_.jpg",
        "details": [
          "Smart TV QLED 4K 85 pollici, Risoluzione 3840x2160",
          "QLED Display",
          "Dolby Vision / HDR 10+ / HLG",
          "Smart TV VIDAA U7 con Alexa Integrato, Airplay 2 & Android Screen Sharing",
          "Audio Dolby Atmos"
        ]
      },
      {
        "id": 12,
        "title": "Hisense TV 75\" Mini-LED 4K 75U72NQ, Smart TV VIDAA U7, Dolby Vision IQ, HDR 10+ Adaptive, 144Hz Game Mode PRO, Alexa Built-in, VIDAA Voice, Tuner DVB-T2/S2 HEVC 10, lativù 4K",
        "brand": "HISENSE",
        "rating": 8.6,
        "price": "1.299,00 €",
        "inches":75,
        "link": "https://amzn.to/4hD9nS7",
        "isTopChoice": false,
        "isDeal": false,
        "discount": null,
        "linkOffer": null,
        "linkImage": "https://m.media-amazon.com/images/I/81C+zxhKMtL._AC_SL1500_.jpg",
        "details": [
          "Smart TV Mini-LED 4K 75\", Risoluzione 3840x2160 QLED Display",
          "144Hz Mini-LED PRO con Local Dimming",
          "Processore Hi-View Engine",
          "Smart TV VIDAA U7 con Alexa Integrato, Airplay 2 & Android Screen Sharing"
        ]
      },
      {
        "id": 13,
        "title": "Hisense 85\" 4K Ultra HD 2023 85E63KT, Smart TV VIDAA U6, Dolby Vision, HDR 10+, Game Mode, Alexa Built-in, Tuner DVB-T2/S2 HEVC 10, lativù 4K",
        "brand": "HISENSE",
        "rating": 9.8,
        "price": "899,90 €",
        "inches": 85,
        "link": "https://amzn.to/40AqrSK",
        "isTopChoice": false,
        "isDeal": true,
        "discount": 25,
        "linkOffer": null,
        "linkImage": "https://m.media-amazon.com/images/I/71rqqEO-dGL._AC_SL1500_.jpg",
        "details": [
          "85\" 4K Ultra HD Smart, risoluzione 3840x2160 con pannello VA",
          "Design Slim, con meno cornici e più immagini",
          "Smart TV VIDAA U6 con controlli vocali, Alexa Built-In, Wi-Fi integrato e telecomando con accesso diretto a molteplici contenuti tra cui Prime Video, Netflix, NOW, DAZN, Disney+ e RaiPlay",
          "Sintonizzatore Digitale Terrestre T2 HDMI 2.0 (Game Mode Plus: ALLM, VRR, Low Input Lag)",
          "Audio 30W DTS Virtual:X"
        ]
      },
      {
        "id": 14,
        "title": "LG OLED evo 83'', Serie C4 2024, OLED83C44LA, Smart TV 4K",
        "brand": "LG",
        "rating": 8.8,
        "price": "3.699,00 €",
        "inches": 83,
        "link": "https://amzn.to/3CrfqZU",
        "isTopChoice": false,
        "isDeal": true,
        "discount": 26,
        "linkOffer": null,
        "linkImage": "https://m.media-amazon.com/images/I/81ZvxMBF+fL._AC_SL1500_.jpg",
        "details": [
          "TECNOLOGIA BRIGHTNESS BOOSTER: immagini fino al 20% più luminose rispetto a uno schermo LG OLED tradizionale.",
          "PROCESSORE α9 GEN7 CON AI: l'innovativo processore sviluppato da LG.",
          "DOLBY VISION E DOLBY ATMOS: per immagini intense e un audio più coinvolgente.",
          "GAMING: gameplay fluido e reattivo, 4 porte HDMI 2.1 4K@144Hz.",
          "SMART TV WEBOS 24 CON THINQ AI: tantissime app di streaming."
        ]
      },
      {
        "id": 15,
        "title": "PHILIPS PHS6808 80 cm (32 pollici) Smart LED TV | 60Hz | Pixel Plus HD & HDR10 | SAPHI | Dolby Atmos | Altoparlanti 10W | Compatibile con Assistente Google & Alexa",
        "brand": "PHILIPS",
        "rating": 7.8,
        "price": "189,00 €",
        "inches": 32,
        "link": "https://amzn.to/3YCHgd9",
        "isTopChoice": false,
        "isDeal": true,
        "discount": 24,
        "linkOffer": null,
        "linkImage": "https://m.media-amazon.com/images/I/81KZT0xIx1L._AC_SL1500_.jpg",
        "details": [
          "SCHERMO: 32 pollici LED, risoluzione 720p, frequenza di aggiornamento 60 Hz.",
          "CONESSIONI: Wi-Fi, USB, Ethernet, HDMI",
          "CARATTERISTICHE: HDR10 supporta contrasto colori, tecnologia Pixel Plus HD offre immagini ultra nitide, controllo vocale tramite Alexa o Assistente Google, Dolby Atmos.",
          "INCLUSO NELLA CONFEZIONE: Telecomando, Guida rapida, Brochure legale e per la sicurezza, cavo di alimentazione, 2 batterie AAA, piedistallo da tavolo."
        ]
      },
      {
        "id": 16,
        "title": "TELEFUNKEN Smart TV 32\" HD Ready TE32553G54V4DAZ",
        "brand": "TELEFUNKEN",
        "rating": 8.2,
        "price": "174,99 €",
        "inches": 32,
        "link": "https://amzn.to/3YV0XOH",
        "isTopChoice": true,
        "isDeal": true,
        "discount": 12,
        "linkOffer": null,
        "linkImage": "https://m.media-amazon.com/images/I/61BULlAl1LL._AC_SL1500_.jpg",
        "details": [
          "ANDROID TV: sistema operativo con vasta gamma di contenuti e app disponibili su Play Store.",
          "32 POLLICI: diagonale del televisore, design moderno e sottile, ideale per ambienti di dimensioni ridotte.",
          "CONTROLLO VOCALE INTELLIGENTE: con Google Assistant per cambiare canali, regolare il volume, aprire app e altro.",
          "HD READY: risoluzione per una buona qualità dell'immagine, ottimale per film, programmi TV o videogiochi.",
          "DOLBY AUDIO: tecnologia audio immersiva e di alta qualità.",
          "CONNESSO: Wi-Fi, Ethernet, 3 HDMI, 2 USB, 1 VGA, 1 CI+, uscita audio ottica digitale, uscita cuffie."
        ]
      },
      {
        "id": 17,
        "title": "Samsung Smart TV 85\" QE85Q70DAUXZT QLED 4K",
        "brand": "SAMSUNG",
        "rating": 8.0,
        "price": "1.599,00 €",
        "inches": 85,
        "link": "https://amzn.to/4hGgfyb",
        "isTopChoice": false,
        "isDeal": true,
        "discount": 11,
        "linkOffer": "null",
        "linkImage": "https://m.media-amazon.com/images/I/81JzcpRfbwL._AC_SL1500_.jpg",
        "details": [
          "Smart TV 85 pollici, Quantum Processor con risoluzione 4K. Design AirSlim sottile. Quantum Dot per colori mozzafiato.",
          "Motion Xcelerator 120Hz per immagini fluide, ideale per il gaming.",
          "OTS Lite: Audio surround 3D sincronizzato con l'azione per un’esperienza sonora immersiva.",
          "Q-Symphony per armonia tra soundbar (non inclusa) e altoparlanti del TV.",
          "Gaming Hub per l'accesso facilitato ai giochi.",
          "Classe di efficienza energetica E."
        ]
      },
      {
        "id": 18,
        "title": "Samsung TV QE85Q60CAUXZT QLED 4K, Smart TV 85\"",
        "brand": "SAMSUNG",
        "rating": 8.8,
        "price": "1.699,00 €",
        "inches": 85,
        "link": "https://amzn.to/4hEbPIb",
        "isTopChoice": false,
        "isDeal": false,
        "discount": null,
        "linkOffer": null,
        "linkImage": "https://m.media-amazon.com/images/I/71lRvO+ASmL._AC_SL1500_.jpg",
        "details": [
          "Processore Quantum 4K Lite",
          "100% volume colore con Quantum Dot",
          "Quantum HDR",
          "Design AirSlim",
          "SmartThings",
          "Audio OTS Lite",
          "Gaming Hub",
          "Dimensioni del prodotto: 41,5P x 190,1l x 108,6H cm",
          "Peso con base: 42,5 kg"
        ]
      },
      {
        "id": 19,
        "title": "TCL 75V6B TV 75”, 4K HDR, Ultra HD, Google TV con design senza bordi, Dolby Audio, compatibile con Google Assistant e Alexa",
        "brand": "TCL",
        "rating": 8.6,
        "price": "699,90 €",
        "inches": 75,
        "link": "https://amzn.to/3CreaWI",
        "isTopChoice": false,
        "isDeal": true,
        "discount": 22,
        "linkOffer": null,
        "linkImage": "https://m.media-amazon.com/images/I/71j3cmtZwVL._AC_SL1500_.jpg",
        "details": [
          "HDR MULTIFORMAT supporta HDR10, HDR HLG, HDR10+, HDR DOLBY VISION",
          "GAME MASTER 2.0 con HDMI 2.1",
          "Google TV organizza film, serie, programmi TV dalle tue app e abbonamenti",
          "Audio immersivo e avvolgente Dolby Audio",
          "Schermo 75 pollici, risoluzione 4K Ultra HD"
        ]
      },
      {
        "id": 20,
        "title": "Hisense 32\" QLED FHD 32E53KQT, Smart TV VIDAA U6 FHD, Game Mode, USB Type-C, Controlli vocali Alexa, Tuner DVB-T2/S2 HEVC 10, Double Position Stand",
        "brand": "HISENSE",
        "rating": 8.6,
        "price": "239,00 €",
        "inches": 32,
        "link": "https://amzn.to/4hGXF9p",
        "isTopChoice": false,
        "isDeal": true,
        "discount": 20,
        "linkOffer": null,
        "linkImage": "https://m.media-amazon.com/images/I/81cEIp5gjTL._AC_SL1500_.jpg",
        "details": [
          "Risoluzione 1920x1080",
          "Design Slim con meno cornici e più immagini",
          "Smart VIDAA U6 con controlli vocali Alexa",
          "Wi-Fi integrato e telecomando con accesso diretto a contenuti come Prime Video, Netflix, DAZN, Disney+ e RaiPlay",
          "Sintonizzatore Digitale Terrestre T2 & Satellitare S2 HEVC 10",
          "HDMI (Game Mode: eARC, Low Input Lag)",
          "USB Type-C",
          "Audio 16W DTS Virtual:X"
        ]
      }
  ];
};

export const fetchPageTitle = async () => {
  // Simulate API delay
  await new Promise(resolve => setTimeout(resolve, 500));
  
  // Mock data for the title
  return "I Migliori TELEVISORI  in offerta in ITALIA";
};
