import React, { useState, useEffect, useTransition, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { CSSTransition } from 'react-transition-group';
import { fetchLaptops, fetchPageTitle } from '../api/laptopApi';
import LaptopItem from './productItem';

const LaptopComparison = () => {
  const [laptops, setLaptops] = useState([]);
  const [pageTitle, setPageTitle] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortBy, setSortBy] = useState('none');
  const [order, setOrder] = useState('desc');
  const [searchTerm, setSearchTerm] = useState('');
  const [isPending, startTransition] = useTransition();
  const [selectedBrands, setSelectedBrands] = useState(new Set());
  const [selectedInches, setSelectedInches] = useState(new Set());

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [laptopsData, titleData] = await Promise.all([
          fetchLaptops(),
          fetchPageTitle()
        ]);
        setLaptops(laptopsData);
        setPageTitle(titleData);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch data. Please try again later.');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const sortLaptops = (laptops, sortBy, order) => {
    if (sortBy === 'none') return laptops;
    return [...laptops].sort((a, b) => {
      let comparison = 0;
      switch (sortBy) {
        case 'rating':
          comparison = a.rating - b.rating;
          break;
        case 'discount':
          comparison = a.discount - b.discount;
          break;
        case 'price':
          const priceA = parseFloat(a.price.replace(/[^0-9,-]+/g, "").replace(",", "."));
          const priceB = parseFloat(b.price.replace(/[^0-9,-]+/g, "").replace(",", "."));
          comparison = priceA - priceB;
          break;
        default:
          return 0;
      }
      return order === 'asc' ? comparison : -comparison;
    });
  };

  const handleSort = (e) => {
    startTransition(() => {
      setSortBy(e.target.value);
    });
  };

  const handleOrderChange = (newOrder) => {
    startTransition(() => {
      setOrder(newOrder);
    });
  };

  const handleSearch = (e) => {
    startTransition(() => {
      setSearchTerm(e.target.value);
    });
  };

  const resetSearch = () => {
    startTransition(() => {
      setSearchTerm('');
    });
  };

  const filterLaptops = (laptops, term) => {
    return laptops.filter(laptop => {
      // First check if the laptop matches the selected brands filter
      const matchesBrand = selectedBrands.size === 0 || selectedBrands.has(laptop.brand);
      if (!matchesBrand) return false;

      // Check inches filter
      const matchesInches = selectedInches.size === 0 || selectedInches.has(laptop.inches);
      if (!matchesInches) return false;

      // If no search term, return all laptops that match the brand filter
      if (!term) return true;

      // Convert search term to lowercase for case-insensitive comparison
      const searchLower = term.toLowerCase();
      
      // Check title
      if (laptop.title.toLowerCase().includes(searchLower)) return true;
      
      // Check brand
      if (laptop.brand.toLowerCase().includes(searchLower)) return true;
      
      // Check price (remove currency symbol and convert to string for search)
      const priceString = laptop.price.toLowerCase().replace(/[^0-9,-]+/g, "");
      if (priceString.includes(searchLower)) return true;
      
      // Check if search term is a number for discount comparison
      const searchNumber = parseFloat(searchLower);
      if (!isNaN(searchNumber) && laptop.discount === searchNumber) return true;
      
      // Check details array
      if (laptop.details.some(detail => detail.toLowerCase().includes(searchLower))) return true;
      
      // If none of the above conditions are met, exclude this laptop
      return false;
    });
  };

  const sortedLaptops = useMemo(() => {
    const filtered = filterLaptops(laptops, searchTerm);
    return sortLaptops(filtered, sortBy, order);
  }, [laptops, searchTerm, sortBy, order, selectedBrands, selectedInches]);

  const listKey = useMemo(() => {
    return `${sortBy}-${order}-${searchTerm}-${Array.from(selectedBrands).join('-')}-${Array.from(selectedInches).join('-')}-${Date.now()}`;
  }, [sortBy, order, searchTerm, selectedBrands, selectedInches]);

  const getCurrentDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Gennaio è 0!
    const year = today.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const brandTags = useMemo(() => {
    let filteredLaptops = laptops;
    
    // Filter by selected inches first
    if (selectedInches.size > 0) {
      filteredLaptops = filteredLaptops.filter(laptop => 
        selectedInches.has(laptop.inches)
      );
    }
    
    // Get unique brands from filtered laptops
    const brands = new Set(filteredLaptops.map(laptop => laptop.brand));
    return Array.from(brands);
  }, [laptops, selectedInches]);

  const inchTags = useMemo(() => {
    let filteredLaptops = laptops;
    
    // Filter by selected brands first
    if (selectedBrands.size > 0) {
      filteredLaptops = filteredLaptops.filter(laptop => 
        selectedBrands.has(laptop.brand)
      );
    }
    
    // Get unique inches from filtered laptops and sort them
    const inches = new Set(filteredLaptops.map(laptop => laptop.inches));
    return Array.from(inches).sort((a, b) => a - b);
  }, [laptops, selectedBrands]);

  const handleBrandClick = (brand) => {
    startTransition(() => {
      setSelectedBrands(prev => {
        const newBrands = new Set(prev);
        if (newBrands.has(brand)) {
          newBrands.delete(brand);
        } else {
          newBrands.add(brand);
        }
        return newBrands;
      });
    });
  };

  const handleInchClick = (inch) => {
    startTransition(() => {
      setSelectedInches(prev => {
        const newInches = new Set(prev);
        if (newInches.has(inch)) {
          newInches.delete(inch);
        } else {
          newInches.add(inch);
        }
        return newInches;
      });
    });
  };

  const getAvailableBrandsCount = (brand) => {
    return laptops.filter(laptop => {
      const matchesInches = selectedInches.size === 0 || selectedInches.has(laptop.inches);
      return laptop.brand === brand && matchesInches;
    }).length;
  };

  const getAvailableInchesCount = (inch) => {
    return laptops.filter(laptop => {
      const matchesBrand = selectedBrands.size === 0 || selectedBrands.has(laptop.brand);
      return laptop.inches === inch && matchesBrand;
    }).length;
  };

  if (loading) return <div className="text-center mt-8">Caricamento...</div>;
  if (error) return <div className="text-center mt-8 text-red-500">{error}</div>;

  return (
    <>
      <Helmet>
        <title>{pageTitle} - ULTIME OFFERTE</title>
        <meta name="description" content={`Confronta i migliori televisori in Italia e trova le ultime offerte. ${pageTitle}`} />
      </Helmet>
      <main className="max-w-5xl mx-auto p-4">
        <h2 className="text-2xl md:text-3xl text-gray-600 font-bold my-4">{pageTitle}</h2>
        <p className="text-sm md:text-base text-gray-600 mb-4">Aggiornato l'ultima volta - {getCurrentDate()}</p>
        <div className="mb-4">
          <div className="flex flex-col md:flex-row md:items-center space-y-2 md:space-y-0 md:space-x-2 mb-4">
            <div className="flex-grow relative">
              <input
                type="text"
                placeholder="Ricerca Intelligente..."
                className="w-full px-3 py-1 pr-10 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-200"
                value={searchTerm}
                onChange={handleSearch}
              />
              {searchTerm && (
                <button
                  onClick={resetSearch}
                  className="absolute right-10 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
                  aria-label="Reset search"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </button>
              )}
              <svg
                className="absolute right-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="flex items-center space-x-2">
              <label htmlFor="orderBy" className="mr-2 font-semibold text-sm md:text-base">Ordina per:</label>
              <select
                id="orderBy"
                className="border border-gray-300 rounded px-3 py-1 transition-colors duration-200 focus:border-blue-500 text-sm md:text-base"
                onChange={handleSort}
                value={sortBy}
              >
                <option value="none">Nessuno</option>
                <option value="rating">Voto</option>
                <option value="discount">Sconto</option>
                <option value="price">Prezzo</option>
              </select>
              <button
                onClick={() => handleOrderChange('asc')}
                className={`p-1 rounded ${order === 'asc' ? 'bg-orange-500 text-white' : 'bg-gray-200'}`}
                aria-label="Ordine crescente"
                disabled={sortBy === 'none'}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M3.293 9.707a1 1 0 010-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L4.707 9.707a1 1 0 01-1.414 0z" clipRule="evenodd" />
                </svg>
              </button>
              <button
                onClick={() => handleOrderChange('desc')}
                className={`p-1 rounded ${order === 'desc' ? 'bg-orange-500 text-white' : 'bg-gray-200'}`}
                aria-label="Ordine decrescente"
                disabled={sortBy === 'none'}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z" clipRule="evenodd" />
                </svg>
              </button>
            </div>
          </div>

          <div className="mt-4">
            <p className="text-sm text-gray-600 mb-2">Filtra per marca:</p>
            <div className="flex flex-wrap gap-2">
              {brandTags.map(brand => (
                <button
                  key={brand}
                  onClick={() => handleBrandClick(brand)}
                  className={`px-3 py-1 rounded-full text-sm transition-all duration-200 ${
                    selectedBrands.has(brand)
                      ? 'bg-orange-500 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {brand} ({getAvailableBrandsCount(brand)})
                  {selectedBrands.has(brand) && (
                    <span className="ml-2 font-bold">×</span>
                  )}
                </button>
              ))}
            </div>
            {selectedBrands.size > 0 && (
              <button
                onClick={() => setSelectedBrands(new Set())}
                className="mt-2 text-sm text-gray-500 hover:text-gray-700 font-bold"
              >
                Rimuovi filtro marca
              </button>
            )}
          </div>

          <div className="mt-4">
            <p className="text-sm text-gray-600 mb-2">Filtra per Pollici:</p>
            <div className="flex flex-wrap gap-2">
              {inchTags.map(inch => (
                <button
                  key={inch}
                  onClick={() => handleInchClick(inch)}
                  className={`px-3 py-1 rounded-full text-sm transition-all duration-200 ${
                    selectedInches.has(inch)
                      ? 'bg-orange-500 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {inch}" ({getAvailableInchesCount(inch)})
                  {selectedInches.has(inch) && (
                    <span className="ml-2 font-bold">×</span>
                  )}
                </button>
              ))}
            </div>
            {selectedInches.size > 0 && (
              <button
                onClick={() => setSelectedInches(new Set())}
                className="mt-2 text-sm text-gray-500 hover:text-gray-700 font-bold"
              >
                Rimuovi filtro pollici
              </button>
            )}
          </div>
        </div>

        <div className="laptop-list" key={listKey}>
          {sortedLaptops.length > 0 ? (
            sortedLaptops.map((laptop) => (
              <div className="laptop-item" key={laptop.id}>
                <LaptopItem {...laptop} />
              </div>
            ))
          ) : (
            <div className="text-center mt-8">Nessun risultato trovato.</div>
          )}
        </div>
      </main>
    </>
  );
};

export default LaptopComparison;
