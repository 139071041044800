import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="max-w-5xl mx-auto p-4">
      <h2 className="text-3xl font-bold my-4">Termini e Condizioni</h2>
      <p className="mb-4">
        Benvenuto su ULTIME OFFERTE. Utilizzando il nostro sito web, accetti di essere vincolato dai seguenti termini e condizioni.
      </p>
      <h3 className="text-xl font-bold my-3">Utilizzo del sito</h3>
      <p className="mb-4">
        Il contenuto di questo sito è solo a scopo informativo. Non garantiamo l'accuratezza, la completezza o l'utilità di queste informazioni.
      </p>
      <h3 className="text-xl font-bold my-3">Proprietà intellettuale</h3>
      <p className="mb-4">
        Tutti i contenuti presenti su questo sito sono di proprietà di ULTIME OFFERTE e sono protetti dalle leggi sul copyright.
      </p>
      <h3 className="text-xl font-bold my-3">Link a siti di terze parti</h3>
      <p className="mb-4">
        Il nostro sito può contenere link a siti web di terze parti. Non siamo responsabili per il contenuto o le pratiche di privacy di questi siti.
      </p>
      <h3 className="text-xl font-bold my-3">Limitazione di responsabilità</h3>
      <p className="mb-4">
        ULTIME OFFERTE non sarà responsabile per eventuali danni derivanti dall'uso o dall'impossibilità di utilizzare i materiali sul nostro sito.
      </p>
      <h3 className="text-xl font-bold my-3">Modifiche ai termini</h3>
      <p className="mb-4">
        Ci riserviamo il diritto di modificare questi termini in qualsiasi momento. Continuando a utilizzare il sito dopo le modifiche, accetti i nuovi termini.
      </p>
    </div>
  );
};

export default TermsAndConditions;
