import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-8">
      <div className="max-w-5xl mx-auto px-4">
        <div className="flex flex-wrap justify-between">
          <div className="w-full md:w-1/3 mb-6 md:mb-0">
            <h3 className="text-xl text-orange-500 font-bold mb-2">ULTIME OFFERTE</h3>
            <p className="text-sm">Il tuo sito di confronto per i migliori prodotti in Italia.</p>
          </div>
          <div className="w-full md:w-1/3 mb-6 md:mb-0">
            <h3 className="text-xl text-orange-500 font-bold mb-2">Link Utili</h3>
            <ul className="text-sm">
              <li className="mb-2"><Link to="/" className="hover:text-gray-300">Home</Link></li>
              <li className="mb-2"><Link to="/chi-siamo" className="hover:text-gray-300">Chi Siamo</Link></li>
              <li className="mb-2"><Link to="/privacy-policy" className="hover:text-gray-300">Privacy Policy</Link></li>
              <li className="mb-2"><Link to="/termini-e-condizioni" className="hover:text-gray-300">Termini e Condizioni</Link></li>
            </ul>
          </div>
          <div className="w-full md:w-1/3">
            <h3 className="text-xl text-orange-500 font-bold mb-2">Contattaci</h3>
            <p className="text-sm mb-2">Email: info@ultimeofferte.com</p>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-gray-700 text-sm text-center">
          <p>&copy; 2024 ULTIME OFFERTE. Tutti i diritti riservati. Nota: Sito Affiliato Amazon.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
